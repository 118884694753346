import React from "react";

import Layout from "../../components/layout";
import { Seo } from "../../components";

import image27 from "../../images/about/image27.png";
import image28 from "../../images/about/image28.png";
import image29 from "../../images/about/image29.png";
import image30 from "../../images/about/image30.png";
import image31 from "../../images/about/image31.png";
import image32 from "../../images/about/image32.jpg";

const LiturgicalColours = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo title="Liturgical colours" />
      <div className="about">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="about-subtitle">Liturgical colours</h3>
              <p>White (Christmas Season) </p>
              <p> Green (Epiphany Season) </p>
              <p>Purple (Lenten Season) </p>
              <p>White (Easter Season) </p>
              <p>Red (Pentecost Season) </p>
              <p>White (Trinity Sunday) </p>
              <p>Green (Pentecost Season) </p>
              <p>Purple (Advent Season)</p>

              <div className="row">
                <div className="col-12 col-md-4">
                  <div className="about-liturgy-img-wrapper">
                    <img src={image27} className="w-100" alt="image27"></img>
                  </div>
                  <p>
                    White is the colour for the festal periods from Christmas
                    Eve to the festival of Epiphany, from Easter day to the eve
                    of Pentecost, and for Trinity Sunday. It is also used for
                    marriages, thanksgiving services, harvest festivals, the
                    feast of dedication of a church and for feasts commemorating
                    saints. White is suitable for baptism, confirmation and
                    ordination, though red may be preferred.
                  </p>
                </div>
                <div className="col-12 col-md-4">
                  <div className="about-liturgy-img-wrapper">
                    <img src={image28} className="w-100" alt="image28"></img>
                  </div>
                  <p>
                    Red is used on the feast of Pentecost, and during the week
                    between Pentecost and Trinity Sunday. Itis used on All
                    Saints’ Day and for the feasts of those saints venerated as
                    martyrs. It is appropriate for any service which focuses on
                    the gift of the Holy Spirit, and is therefore suitable for
                    baptism, confirmation and ordination.
                  </p>
                </div>
                <div className="col-12 col-md-4">
                  <div className="about-liturgy-img-wrapper">
                    <img src={image29} className="w-100" alt="image29"></img>
                  </div>
                  <p>
                    Purple is the colour for Advent season and Lenten season and
                    is therefore used from the first Sunday in Advent to the
                    fourth Sunday in Advent; from Ash Wednesday until Holy
                    Saturday. However, white may be preferred at Holy Communion
                    service on Maundy Thursday.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-4">
                  <div className="about-liturgy-img-wrapper">
                    <img src={image30} className="w-100" alt="image30"></img>
                  </div>
                  <p>
                    Purple is recommended for funerals and for the commemoration
                    of the faithful departed, although either black or white may
                    also be used. White is preferred at the funeral of a child.
                  </p>
                </div>
                <div className="col-12 col-md-4">
                  <div className="about-liturgy-img-wrapper">
                    <img src={image31} className="w-100" alt="image31"></img>
                  </div>
                  <p>
                    Green is the colour for ordinary Sundays. It is used between
                    Epiphany and Ash Wednesday, and between Trinity Sunday and
                    Advent Sunday. Orange / Saffron CSI Episcopacy has
                    instituted Orange/Saffron Handbands and stoles for its
                    Bishops.
                  </p>
                </div>
                <div className="col-12 col-md-4">
                  <div className="about-liturgy-img-wrapper">
                    <img src={image32} className="w-100" alt="image32"></img>
                  </div>
                  <p>
                    Orange is colour of India &amp; it is on the top of the
                    Indian Flag symbolizing Indian spirituality of Prayer,
                    Meditation, Sacrifice, Selflessness and Commitment. The CSI
                    Episcopal vestments do not change the colours throughout the
                    year, for Episcopacy stands for continuity of Pastoral
                    Leadership, Solemnity &amp; Dignity of Liturgical
                    continuance and perpetual Theological reflection and
                    commitment to God and to His Word and to His Church.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LiturgicalColours;
